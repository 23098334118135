<template>
  <div class="card">
    <div class="card-body pt-4 mt-5 steps">
        <div class="row">
            <div class="col-md-5" v-if="active == 0">
                <div class="alert alert-primary d-flex align-items-center p-5 mb-10">
                  <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                      <inline-svg src="media/icons/duotune/general/gen048.svg" />
                  </span>
                  <div class="d-flex">
                      <h4 class="mt-3 text-primary">Sample Order File Download</h4>
                      <span class="ms-2">
                      <a href="javascript:void()" class="mt-1 btn btn-sm btn-flex btn-light-primary btn-active-primary fw-bolder" @click="downloadSampleFile('xlsx')">
                      <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1"> <i class="bi bi-file-earmark-arrow-down fs-4"></i> </span>
                      {{generalConstants.DOWNLOAD_EXCEL}}
                      </a>
                      </span>

                      <span class="ms-2">
                      <a href="javascript:void()" class="mt-1 btn btn-sm btn-flex btn-light-primary btn-active-primary fw-bolder" @click="downloadSampleFile('csv')">
                      <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1"> <i class="bi bi-file-earmark-arrow-down fs-4"></i> </span>
                      {{generalConstants.DOWNLOAD_CSV}}
                      </a>
                      </span>
                  </div>
                </div>
            </div>
        </div>
      <el-steps :active="active" finish-status="success">
        <el-step :title="orderImportConstants.ORDERSIMPORT" />
        <el-step :title="generalConstants.COLUMNMAPPING" />
        <el-step :title="orderImportConstants.ORDERSTATISTICS" />
        <el-step :title="generalConstants.FINISH" class="text-end" />
      </el-steps>
  
      <el-form
        @submit.prevent="submitOrderImportForm()"
        :model="orderImportFormData"
        :rules="rules"
        ref="orderFormRef"
        class="w-100 mt-10"
      >  
      <div class="row text-center" v-if="active == 0">
        <div class="col-md-12 fv-row">
          <label class="fw-bold">{{ generalConstants.SELECTTRADINGPARTNER }}</label>
          <el-form-item prop="tradingPartnerId" class="import-form-item">
            <el-select filterable :placeholder="generalConstants.SELECTTRADINGPARTNER" v-model="orderImportFormData.tradingPartnerId" size="large">
              <el-option :label="tradingData.store_name" :value="tradingData.trading_partner_id" :key="tradingData"  v-for="tradingData in tradingPartnerData"></el-option>
            </el-select>
          </el-form-item>
          <label class="fw-bold">{{ orderImportConstants.UPLOADORDEREXCEL }}</label>
          <el-form-item prop="excelFiles" class="import-form-item">
            <el-upload
              ref="excelFiles"
              v-model="orderImportFormData.excelFiles"
              :auto-upload="false"
              :limit="2"
              :multiple="false"
              :on-remove="handleRemove"
              v-on:change="handleChange"
              :file-list="fileList"
              drag
              class="upload-exel"
              action=""
            >
            <div class="fv-row">
              <div class="dropzone" id="kt_dropzonejs_example_1">
                <div class="dz-message needsclick">
                  <i class="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                  <div class="ms-4">
                    <h3 class="fs-5 fw-bolder text-gray-900 mb-1 mt-4" v-html="generalConstants.UPLOADFILEPLACETEXT"></h3>
                  </div>
                </div>
              </div>
            </div>
          </el-upload>
          </el-form-item>
        </div>
      </div>
      <span class="row mt-10" v-if="active == 1" v-loading="loading">
        <div class="row">
          <h4>{{ orderImportConstants.ORDER_DETAILS }}</h4>
          <div class="col-md-3 mb-5" v-for="(header, index) in orderMappingOptions.orderData" :key="index">
            <div class="col-md-8">
              <label :class="checkValidation(index) == true ? 'required' : ''">{{ formatText(index) }}</label>
            </div>
            <div class="col-md-10 col-9">
              <div class="d-flex align-items-center">
                <div class="me-5" v-if="index != 'order_extrafields'">
                  <el-select filterable clearable v-model="orderMappingOptions.orderData[index]" :key="index" :placeholder="orderImportConstants.SELECT_ORDER_MAPPING" size="large" @change="setTradingMappingData($event,index)" :class="header != '' ? 'mapping-color' : ''">
                    <el-option v-for="item in excelColumns" :key="item.value" :label="item.label" :value="item.value">
                    <span v-if="item.fill == ''">{{ item.label }}</span>
                    <span v-else style="color: #009ef7">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </div>
                <div class="me-5" v-else>
                    <el-select filterable clearable  multiple v-model="orderMappingOptions.orderData[index]" :key="index" :placeholder="orderImportConstants.SELECT_ORDER_MAPPING" size="large" :class="header != '' ? 'mapping-color' : ''">
                      <el-option v-for="item in excelColumns"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                </div>
              </div>
            </div>
          </div>
          <h4>{{ orderImportConstants.CUSTOMER_DETAILS }}</h4>
          <div class="col-md-3 mb-5" v-for="(header, index) in orderMappingOptions.customerData" :key="index">
            <div class="col-md-8">
              <label :class="checkValidation(index) == true ? 'required' : ''">{{ formatText(index) }}</label>
            </div>
            <div class="col-md-10 col-9"> 
              <div class="d-flex align-items-center">
                  <div class="me-5" v-if="index != 'customer_extrafields'">
                    <el-select filterable clearable v-model="orderMappingOptions.customerData[index]" :key="index" :placeholder="orderImportConstants.SELECT_ORDER_MAPPING" size="large" @change="setTradingMappingData($event,index)" :class="header != '' ? 'mapping-color' : ''">
                      <el-option
                        v-for="item in excelColumns"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                </div>
                <div class="me-5" v-else>
                    <el-select filterable clearable multiple v-model="orderMappingOptions.customerData[index]" :key="index" :placeholder="orderImportConstants.SELECT_ORDER_MAPPING" size="large" :class="header != '' ? 'mapping-color' : ''">
                      <el-option
                        v-for="item in excelColumns"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                </div>
              </div>
            </div>
          </div>
          <h4>{{ orderImportConstants.SHIPPING_DETAILS }}</h4>
          <div class="col-md-3 mb-5" v-for="(header, index) in orderMappingOptions.shippingData" :key="index">
            <div class="col-md-8">
              <label :class="checkValidation(index) == true ? 'required' : ''">{{ formatText(index) }}</label>
            </div>
            <div class="col-md-10 col-9"> 
              <div class="d-flex align-items-center">
                  <div class="me-5">
                    <el-select filterable clearable v-model="orderMappingOptions.shippingData[index]" :key="index" :placeholder="orderImportConstants.SELECT_ORDER_MAPPING" size="large" @change="setTradingMappingData($event,index)" :class="header != '' ? 'mapping-color' : ''">
                      <el-option
                        v-for="item in excelColumns"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                </div>
              </div>
            </div>
          </div>
          <h4>{{ orderImportConstants.BILLING_DETAILS }}</h4>
          <div class="col-md-3 mb-5" v-for="(header, index) in orderMappingOptions.billingData" :key="index">
            <div class="col-md-8">
              <label :class="checkValidation(index) == true ? 'required' : ''">{{ formatText(index) }}</label>
            </div>
            <div class="col-md-10 col-9"> 
              <div class="d-flex align-items-center">
                  <div class="me-5">
                    <el-select filterable clearable v-model="orderMappingOptions.billingData[index]" :key="index" :placeholder="orderImportConstants.SELECT_ORDER_MAPPING" size="large" @change="setTradingMappingData($event,index)" :class="header != '' ? 'mapping-color' : ''">
                      <el-option
                        v-for="item in excelColumns"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                </div>
              </div>
            </div>
          </div>
          <h4>{{ orderImportConstants.BLIND_DETAILS }}</h4>
          <div class="col-md-3 mb-5" v-for="(header, index) in orderMappingOptions.blindData" :key="index">
            <div class="col-md-8">
              <label :class="checkValidation(index) == true ? 'required' : ''">{{ formatText(index) }}</label>
            </div>
            <div class="col-md-10 col-9"> 
              <div class="d-flex align-items-center">
                  <div class="me-5">
                    <el-select filterable clearable v-model="orderMappingOptions.blindData[index]" :key="index" :placeholder="orderImportConstants.SELECT_ORDER_MAPPING" size="large" @change="setTradingMappingData($event,index)" :class="header != '' ? 'mapping-color' : ''">
                      <el-option
                        v-for="item in excelColumns"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                </div>
              </div>
            </div>
          </div>
          <h4>{{ orderImportConstants.PRODUCT_DETAILS }}</h4>
          <div class="col-md-3 mb-5" v-for="(header, index) in orderMappingOptions.productData" :key="index">
            <div class="col-md-8">
              <label :class="checkValidation(index) == true ? 'required' : ''">{{ formatText(index) }}</label>
            </div>
            <div class="col-md-10 col-9"> 
              <div class="d-flex align-items-center">
                  <div class="me-5" v-if="index != 'product_extrafields'">
                    <el-select filterable clearable v-model="orderMappingOptions.productData[index]" :key="index" :placeholder="orderImportConstants.SELECT_ORDER_MAPPING" size="large" @change="setTradingMappingData($event,index)" :class="header != '' ? 'mapping-color' : ''">
                      <el-option
                        v-for="item in excelColumns"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                </div>
                <div class="me-5" v-else>
                  <el-select filterable clearable multiple v-model="orderMappingOptions.productData[index]" :key="index" :placeholder="orderImportConstants.SELECT_ORDER_MAPPING" size="large" :class="header != '' ? 'mapping-color' : ''">
                    <el-option
                      v-for="item in excelColumns"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <el-checkbox v-model="saveColumnCheckbox" :label="orderImportConstants.SAVE_COLUMN" />
          </div>
        </div>
      </span>

      <span v-if="active == 2">
        <div class="row">
          <div class="col-md-3">
            <div class="col bg-light-primary px-6 py-8 rounded-2 me-7 mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-primary fw-bold fs-6"> {{ generalConstants.TOTAL_ROWS }} <h1 class="text-primary"><strong>{{ totalOrderCount }}</strong></h1></span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="col bg-light-success px-6 py-8 rounded-2 me-7 mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-success fw-bold fs-6"> {{ orderImportConstants.NEWORDERFOUND }} <h1 class="text-success"><strong>{{ newOrderCount }}</strong></h1></span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="col bg-light-success px-6 py-8 rounded-2 me-7 mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-success fw-bold fs-6"> {{ orderImportConstants.NEWORDERPRODUCTFOUND }} <h1 class="text-success"><strong>{{ newOrderProductCount }}</strong></h1></span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="col bg-light-success px-6 py-8 rounded-2 me-7 mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-success fw-bold fs-6"> {{ orderImportConstants.EXISTORDERFOUND }} <h1 class="text-success"><strong>{{ existOrderCount }}</strong></h1></span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="col bg-light-success px-6 py-8 rounded-2 me-7 mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-success fw-bold fs-6"> {{ orderImportConstants.EXISTORDERPRODUCTFOUND }} <h1 class="text-success"><strong>{{ existOrderProductCount }}</strong></h1></span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="col bg-light-success px-6 py-8 rounded-2 me-7 mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-success fw-bold fs-6"> {{ orderImportConstants.PENDINGORDERFOUND }} <h1 class="text-success"><strong>{{ tempOrderCount }}</strong></h1></span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="col bg-light-success px-6 py-8 rounded-2 me-7 mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-success fw-bold fs-6"> {{ orderImportConstants.PENDINGORDERPRODUCTFOUND }} <h1 class="text-success"><strong>{{ tempOrderProductCount }}</strong></h1></span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="col bg-light-danger px-6 py-8 rounded-2 me-7  mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-danger fw-bold fs-6"> {{ generalConstants.SKIPPED_ROWS }} <h1 class="text-danger"><strong>{{ totalEmptyOrderFound }}</strong></h1></span>
            </div>
          </div>
        </div>
      </span>

      <span class="row mt-10" v-if="active > 3">
        <div class="row">
          <div class="col-md-12">
            <el-result
              icon="success"
              :title="generalConstants.SUCCESS"
              :sub-title="orderImportConstants.ORDERSUCCESSMSG"
            >
            <template #extra>
            <a href="javascript:void(0)" v-if="orderProductCount > 0" type="button" class="btn btn-primary btn-sm me-2" @click="$router.push({ name: 'orders' })"><i class="bi bi-arrow-right fs-4"></i> {{ orderImportConstants.NEWORDERBTN }}</a>
            <a href="javascript:void(0)" v-if="orderPendingProductCount > 0" type="button" class="btn btn-primary btn-sm" @click="$router.push({ name: 'pending-orders' })"><i class="bi bi-arrow-right fs-4"></i> {{ orderImportConstants.PENDINGORDERBTN }}</a>
            </template>
            </el-result>
          </div>
        </div>
      </span>
      <div class="col-md-12 mt-10 d-flex justify-content-between">
        <div>
          <button type="button" size="large" v-bind:class="btnHide" :disabled="disabled" v-if="active > 0" class=" btn btn-primary me-2" @click="back">{{generalConstants.PREVIOUS}}</button>
        </div>
        <div>
          <button type="button" size="large" v-bind:class="btnHide" class="btn btn-primary me-2" v-if="active < 3" @click="submitOrderImportForm(orderFormRef)">{{generalConstants.NEXT}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
          <button type="button" size="large" class=" btn btn-secondary me-2" v-bind:class="btnHide" @click.prevent="$router.push({ name: 'orders'})">{{generalConstants.CANCEL}}</button>
        </div>
      </div>
    </el-form>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import useEventBus from '../../composable/useEventBus';
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import axios from "axios";

export default {
  name: "orders-import",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const route = useRoute();
    const router = useRouter();
    const generalConstants = globalConstant.general;
    const orderImportConstants = globalConstant.orderImport;
    const btnHide = ref();
    const formRef = ref(null);
    const loading = ref(false);
    const file = ref();
    const active = ref(0);
    const disabled = ref(true);
    const tradingPartnerData = reactive([]);
    const excelColumns = ref([]);
    const saveColumnCheckbox = ref(false)
    const totalOrderCount = ref(0);
    const tempOrderCount = ref(0);
    const tempOrderProductCount = ref(0);
    const newOrderCount = ref(0);
    const newOrderProductCount = ref(0);
    const existOrderCount = ref(0);
    const existOrderProductCount = ref(0);
    const totalEmptyOrderFound = ref(0);
    const orderProductCount = ref(0);
    const orderPendingProductCount = ref(0);
    const fileList = ref([]);

    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "back"){
        router.push('/orders');
      }
    })

    const rules = ref({
      tradingPartnerId: [ { required: true, message: "Trading partner is required", trigger: "change" } ],
      excelFiles: [ { required: true, message: "Excel file is required", trigger: "change" } ],
    });

    const downloadSampleFile = (extension) => {
      window.open(process.env.VUE_APP_API_URL+'../../../sample_orders.'+extension, '_blank');
    }

    const setTradingMappingData = (val,mapping_key) => {
      if(val != ''){
        let lastIndex = Object.values(excelColumns.value).pop();
        let findKey = excelColumns.value.findIndex(p => p.value == val);
        if(findKey != ''){
          excelColumns.value[findKey].sort_order = (lastIndex.sort_order + 1);
          excelColumns.value[findKey].fill = mapping_key;
        }
        excelColumns.value.sort((a,b) => (a.sort_order > b.sort_order) ? 1 : ((b.sort_order > a.sort_order) ? -1 : 0))
        if(mapping_key in validateOrderMappingField.value){
          validateOrderMappingField.value[mapping_key] = val;
        }
      }else{
        validateOrderMappingField.value[mapping_key] = "";
      }
    }

    const validateOrderMappingField = ref({order_id: "", customer_email:"", shipping_name:"", shipping_address_1:"", shipping_address_2:"", shipping_city:"", shipping_postcode:"", shipping_state:"", shipping_state_code: "", shipping_country:"", shipping_country_code: "",shipping_phone:"",  subtotal:"", total_amount:"", payment_method:"", product_sku: "", product_quantity: ""});

    const checkValidation = (val) => {
      if(val in validateOrderMappingField.value){
        return true;
      }else{
        return false;
      }
    }

    const orderMappingOptions = reactive({
      orderData: {
        order_id: "",
        subtotal: "",
        total_amount: "",
        payment_method: "",
        order_weight: "",
        order_shipping_days: "",
        order_production_days: "",
        invoice_number: "",
        production_due_date: "",
        order_date: "",
        order_due_date: "",
        payment_status: "",
        transaction_id: "",
        payment_date: "",
        order_status: "",
        shipping_charges: "",
        blind_shipping_charges: "",
        discount: "",
        tax: "",
        payment_processing_fees: "",
        order_extrafields: []
      },
      productData: {
        product_sku: "",
        product_quantity: "",
        order_product_id: "",
        product_name: "",
        product_size_name: "",
        product_width: "",
        product_height: "",
        product_unit: "",
        product_weight: "",
        product_price: "",
        product_shipping_price: "",
        product_discount: "",
        product_tax: "",
        product_subtotal: "",
        product_production_days: "",
        product_shipping_days: "",
        product_status: "",
        product_extrafields: []
      },
      customerData: {
        customer_email: "",
        customer_name: "",
        customer_company: "",
        customer_phone: "",
        corporate_name: "",
        corporate_department_name: "",
        customer_extrafields: []
      },
      shippingData: {
        shipping_name: "",
        shipping_address_1: "",
        shipping_address_2: "",
        shipping_city: "",
        shipping_state: "",
        shipping_state_code: "",
        shipping_country: "",
        shipping_country_code: "",
        shipping_postcode: "",
        shipping_phone: "",
        shipping_method: "",
        shipping_type: "",
        shipping_company: "",
        shipping_type_key: "",
      },
      billingData: {
        billing_name: "",
        billing_company: "",
        billing_address_1: "",
        billing_address_2: "",
        billing_city: "",
        billing_postcode: "",
        billing_state: "",
        billing_state_code: "",
        billing_country: "",
        billing_country_code: "",
        billing_phone: ""
      },
      blindData: {
        blind_name: "",
        blind_company: "",
        blind_address_1: "",
        blind_address_2: "",
        blind_city: "",
        blind_postcode: "",
        blind_state: "",
        blind_state_code: "",
        blind_country: "",
        blind_country_code: "",
        blind_phone: "",
        blind_shipping: ""
      },
    });

    const handleRemove = (e) => {
      orderImportFormData.value.excelFiles = '';
    }

    const handleExceed = (e) => {
      file.value = e.raw;
      orderImportFormData.value.excelFiles = e.raw;
    }

    const checkSelectedVal = (val,alldata, k) => {
      alldata.keyData.forEach((obj) => {
        if(obj.mapping_key == val){
          obj.mapping_key = '';
        }
      });
      alldata.keyData[k].mapping_key = val;
    }

    const orderImportFormData = ref({
      tradingPartnerId: "",
      excelFiles: "",
    });  

    const orderFormRef = ref(null);

    const back = () => {
      if (active.value-- < 2) active.value = 0
    }

    let searchParams = {
      activeRecord: true,
    };

    const getTradingPartners = async () => {
      await ApiService.query("trading-partner", { params: searchParams })
      .then(({ data }) => {
        data.data.forEach((obj) => {
          tradingPartnerData.push({
            trading_partner_id: obj.trading_partner_id,
            store_name: obj.name,
          });
        });
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let message = response.data.message;
          notificationFire(message, "error");
        }
      });
    }

    const handleChange = (e) => {
      if(fileList.value.length > 1){
        fileList.value.splice(0, 1);
      }
      file.value = e.raw;
      orderImportFormData.value.excelFiles = e.raw;
    }

    const submitOrderImportForm = async (formEl) => {
      if (!orderFormRef.value) {
        return;
      }
      orderFormRef.value.validate((valid) => {
        if (!valid) return false;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        if(active.value == 0){
          loading.value = true;
          disabled.value = true;
          let formData = new FormData();
          formData.append('file', file.value);
          formData.append('steps', active.value);
          formData.append('tradingPartnerId', orderImportFormData.value.tradingPartnerId);
          axios.post('orders/order-import', formData)
          .then(({ data }) => {
              if(data.data){
                if(data.data.mappingData.order_mapping != null){
                  for (const key in orderMappingOptions.orderData) {
                    if(key == 'order_extrafields'){
                      const tempOrdreFieldArray = [];
                      for (const orderFieldKey in data.data.mappingData.order_mapping.orderData[key]){
                        if(data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.orderData[key][orderFieldKey])){
                          tempOrdreFieldArray.push(data.data.mappingData.order_mapping.orderData[key][orderFieldKey]);
                        }
                      }
                      orderMappingOptions.orderData[key] = tempOrdreFieldArray.length > 0 ? tempOrdreFieldArray : [];
                    }else{
                      if(data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.orderData[key])){
                        orderMappingOptions.orderData[key] = data.data.mappingData.order_mapping.orderData[key];
                      }
                      if(key in validateOrderMappingField.value && data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.orderData[key])){
                        validateOrderMappingField.value[key] = data.data.mappingData.order_mapping.orderData[key];
                      }
                    }
                  }
                  for (const key in orderMappingOptions.productData) {
                    if(key == 'product_extrafields'){
                      const tempProductFieldArray = [];
                      for (const productFieldKey in data.data.mappingData.order_mapping.productData[key]){
                        if(data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.productData[key][productFieldKey])){
                          tempProductFieldArray.push(data.data.mappingData.order_mapping.productData[key][productFieldKey]);
                        }
                      }
                      orderMappingOptions.productData[key] = tempProductFieldArray.length > 0 ? tempProductFieldArray : []
                    }else{
                      if(data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.productData[key])){
                        orderMappingOptions.productData[key] = data.data.mappingData.order_mapping.productData[key];
                      }
                      if(key in validateOrderMappingField.value && data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.productData[key])){
                        validateOrderMappingField.value[key] = data.data.mappingData.order_mapping.productData[key];
                      }
                    }
                  }
                  for (const key in orderMappingOptions.shippingData) {
                    if(data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.shippingData[key])){
                      orderMappingOptions.shippingData[key] = data.data.mappingData.order_mapping.shippingData[key];
                    }
                    if(key in validateOrderMappingField.value && data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.shippingData[key])){
                      validateOrderMappingField.value[key] = data.data.mappingData.order_mapping.shippingData[key];
                    }
                  }
                  for (const key in orderMappingOptions.billingData) {
                    if(data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.billingData[key])){
                      orderMappingOptions.billingData[key] = data.data.mappingData.order_mapping.billingData[key];
                    }
                    if(key in validateOrderMappingField.value && data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.billingData[key])){
                      validateOrderMappingField.value[key] = data.data.mappingData.order_mapping.billingData[key];
                    }
                  }
                  for (const key in orderMappingOptions.blindData) {
                    if(data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.blindData[key])){
                      orderMappingOptions.blindData[key] = data.data.mappingData.order_mapping.blindData[key];
                    }
                    if(key in validateOrderMappingField.value && data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.blindData[key])){
                      validateOrderMappingField.value[key] = data.data.mappingData.order_mapping.blindData[key];
                    }
                  }
                  for (const key in orderMappingOptions.customerData) {
                    if(key == 'customer_extrafields'){
                      const tempCustomerFieldArray = [];
                      for (const customerFieldKey in data.data.mappingData.order_mapping.customerData[key]){
                        if(data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.customerData[key][customerFieldKey])){
                          tempCustomerFieldArray.push(data.data.mappingData.order_mapping.customerData[key][customerFieldKey]);
                        }
                      }
                      orderMappingOptions.customerData[key] = tempCustomerFieldArray.length > 0 ? tempCustomerFieldArray : []
                    }else{
                      if(data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.customerData[key])){
                        orderMappingOptions.customerData[key] = data.data.mappingData.order_mapping.customerData[key];
                      }
                      if(key in validateOrderMappingField.value && data.data.excelHeaderKeys.includes(data.data.mappingData.order_mapping.customerData[key])){
                        validateOrderMappingField.value[key] = data.data.mappingData.order_mapping.customerData[key];
                      }
                    }
                  } 
                  saveColumnCheckbox.value = true;
                }

                if(data.data.excelHeaderKeys.length > 0){
                  excelColumns.value = [];
                  for (let i = 0; i < data.data.excelHeaderKeys.length; i++) {
                    excelColumns.value.push({
                      value: data.data.excelHeaderKeys[i],
                      label: data.data.excelHeaderKeys[i],
                      fill: '',
                      sort_order: i
                    });
                  }
                }
                sessionStorage.setItem("orderFileName", data.data.orderFileName);
                sessionStorage.setItem("orderFileExt", data.data.orderFileExt);
                active.value = 1;
              }else{
                notificationFire(data.message, "warning");
              }
              disabled.value = false;
              loading.value = false;
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            loading.value = false;
          });
        }
        if(active.value == 1){
          loading.value = true;
          disabled.value = true;
          let formData = new FormData();
          formData.append('file', file.value);
          formData.append('steps', active.value);
          formData.append('tradingPartnerId', orderImportFormData.value.tradingPartnerId);
          formData.append('validateOrderMappingField', JSON.stringify(validateOrderMappingField.value));
          formData.append('importData', JSON.stringify(orderMappingOptions));
          if(sessionStorage.getItem("orderFileName")){
            formData.append('orderFileName', sessionStorage.getItem("orderFileName"));
          }
          if(sessionStorage.getItem("orderFileExt")){
            formData.append('orderFileExt', sessionStorage.getItem("orderFileExt"));
          }
          axios.post('orders/order-import', formData)
          .then(({ data }) => {
            totalOrderCount.value = data.data.totalOrderCount; 
            tempOrderCount.value = data.data.tempOrderCount;
            tempOrderProductCount.value = data.data.tempOrderProductCount;
            newOrderCount.value = data.data.newOrderCount;
            newOrderProductCount.value = data.data.newOrderProductCount;
            existOrderCount.value = data.data.existOrderCount;
            existOrderProductCount.value = data.data.existOrderProductCount;
            totalEmptyOrderFound.value = data.data.totalEmptyOrderFound;
            loading.value = false;
            disabled.value = false;
            active.value = 2;
          })  
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            loading.value = false;
            disabled.value = false;
          });
        }
        
        if(active.value == 2){
          loading.value = true;
          disabled.value = true;
          let formData = new FormData();
          formData.append('file', file.value);
          formData.append('steps', active.value);
          formData.append('tradingPartnerId', orderImportFormData.value.tradingPartnerId);
          formData.append('importData', JSON.stringify(orderMappingOptions));
          formData.append('saveColumnCheckbox', JSON.stringify(saveColumnCheckbox.value));
          formData.append('steps', active.value);
          if(sessionStorage.getItem("orderFileName")){
            formData.append('orderFileName', sessionStorage.getItem("orderFileName"));
          }
          if(sessionStorage.getItem("orderFileExt")){
            formData.append('orderFileExt', sessionStorage.getItem("orderFileExt"));
          }
          axios.post('orders/order-import', formData)
          .then(({ data }) => {
            btnHide.value = 'd-none';
            notificationFire(data.message, "success");
            disabled.value = false;
            loading.value = false;
            active.value = 4;
            getPendingOrdersCount();
            orderProductCount.value = data.data.orderProductCount;
            orderPendingProductCount.value = data.data.pendingOrderProductCount;
          })  
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            loading.value = false;
            disabled.value = false;
          });
        }
      });
    };

    const getPendingOrdersCount = async () => {
      await ApiService.post("/pending-orders-count", {})
      .then(({ data }) => {
        if (data.pendingOrderProduct != undefined) {
          emitEvent('counterUpdate',{"pendingOrders":data.pendingOrders})
        }
        if (data.pendingOrderProduct != undefined) {
          emitEvent('counterUpdate',{"pendingOrderProducts":data.pendingOrderProduct})
        }
      })
      .catch((error) => {
        //Toast the error here
      });
    }

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name": generalConstants.BACK , 'action': 'back','class': 'btn-primary','isLoader':false, 'iconClass':"bi bi-arrow-left fs-4" }]);
    }

    //Set breadcrumbs and get the trading partners
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name" : formatText('orders'), 'path': 'orders'}, { name: formatText(route.name), active: true }]);
      await getTradingPartners();
      generateHeaderToolbarButtons();
    })

    return {
      file,
      btnHide,
      loading,
      rules,
      tradingPartnerData,
      active,
      disabled,
      back,
      submitOrderImportForm,
      orderFormRef,
      orderImportFormData,
      checkSelectedVal,
      handleExceed,
      handleRemove,
      totalOrderCount,
      tempOrderCount,
      tempOrderProductCount,
      newOrderCount,
      newOrderProductCount,
      existOrderCount,
      existOrderProductCount,
      totalEmptyOrderFound,
      generalConstants,
      orderImportConstants,
      downloadSampleFile,
      orderMappingOptions,
      excelColumns,
      saveColumnCheckbox,
      validateOrderMappingField,
      checkValidation,
      fileList,
      handleChange,
      setTradingMappingData,
      formatText,
      orderProductCount,
      orderPendingProductCount,
    };
  },
};
</script>
<style>
  .upload-exel .el-upload-dragger{border:none !important;height:auto;padding:0 !important;}
  .import-form-item .el-form-item__content {
    justify-content: center;
  }
  .import-form-item .el-form-item__error {
    right: 0;
  }
  .steps .el-step__icon{
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .steps .el-step__head.is-process{
    color: #009ef7;
    font-weight:700;
  }
  .steps .el-step.is-horizontal .el-step__line{
    top:50%;
    display: block;
  }
  .steps .el-step__head.is-process {  
  border-color: #009ef7;
  }
  .steps .el-step__main{
    color: #009ef7;
  }
  .steps .el-step__title.is-process{
    color: #009ef7 !important;
  }
  .steps  .el-step__title.is-success {
    color:#009ef7;
    font-weight: 700;
  }
  .steps .el-step__head.is-success {
    color:#fff;
  }
  .steps  .el-step__head.is-success .el-step__line{
    background-color: #009ef7;
  }
  .steps  .el-step__head.is-success .el-step__icon{
    border-color: #009ef7;
    background-color: #009ef7;
  }
  .steps  .el-step__head.is-success{
    border-color: #009ef7;
  }
  .steps .form-check-input {
    width: 1.30em;
    height: 1.30rem;
    margin-right: 10px;
  }
  .mapping-color .el-input__inner, .mapping-color .el-tag--light {
      color:#009ef7;
  }
</style>